<template>
  <div class="page">
    <div class="top">
      <a-input
        placeholder="输入标题进行搜索"
        v-model="searchInpt"
        @pressEnter="search"
        @change="valueNull"
      />
      <button class="search" @click="search">搜索</button>
      <button class="add" @click="openModal()">新增</button>
    </div>
    <div class="page-body">
      <a-table
        :columns="list"
        :data-source="data"
        rowKey="id"
        @change="changePage"
        :pagination="pagination"
      >
        <a slot="name" slot-scope="text">{{ text }}</a>
        <div slot="setup" slot-scope="row">
          <a @click="openModal(row)" style="margin-right: 5px">编辑</a>
          <a-popconfirm
            title="确定要删除此项么？"
            ok-text="确认"
            cancel-text="取消"
            @confirm="remove(row.id)"
          >
            <a style="margin-left: 5px">删除</a>
          </a-popconfirm>
        </div>
      </a-table>
    </div>
    <a-modal
      v-model="visible"
      :title="innerTitle"
      ok-text="确认"
      cancel-text="取消"
      @cancel="resetForms"
      @ok="hideModal"
    >
      <div class="modal">
        <p>标题</p>
        <a-input placeholder="" v-model="title" />
        <p>排序</p>
        <a-input placeholder="" v-model="sort" />
        <p>内容</p>
        <div style="height: 350px">
          <div style="margin-bottom: 10px">
            <a-upload
              style="margin-right: 10px;"
              :action="ip + '/file/upload'"
              :multiple="true"
              :showUploadList="false"
              @change="handleChangeEdit"
            >
              <a-button class="util-item" type="primary" size="small"
                >插入图片</a-button
              >
            </a-upload>
          </div>
          <quill-editor
            ref="myTextEditor"
            v-model="content"
            :options="editorOption"
            style="height: 250px"
          ></quill-editor>
        </div>
      </div>
    </a-modal>
  </div>
</template>
<script>
import "quill/dist/quill.core.css";
import "quill/dist/quill.snow.css";
import "quill/dist/quill.bubble.css";
import { quillEditor } from "vue-quill-editor";
import { mapState } from "vuex";
import quillConfig from "@/untils/qullsample.config";
export default {
  components: {
    quillEditor,
  },
  data() {
    return {
      editorOption: {
        ...quillConfig,
        placeholder: "编辑文章内容",
      },
      innerTitle: "123",
      visible: false,
      title: "",
      content: "",
      openType: "",
      type: "",
      sort: "",
      list: [
        { title: "序号", dataIndex: "serialNumber", align: "center" },
        {
          title: "标题",
          align: "center",
          dataIndex: "title",
        },

        {
          title: "内容",
          dataIndex: "content",
          align: "center",
        },
        {
          title: "排序",
          dataIndex: "sort",
          align: "center",
        },

        {
          title: "操作",
          align: "center",
          scopedSlots: { customRender: "setup" },
        },
      ],
      data: [],
      pagination: {
        pageSize: 10,
        total: 0,
        current: 1,
        showTotal: (total) => `共${total}条`,
      },
      params: { pageNum: 1, pageSize: 10, title: "" },
      id: "",
      searchInpt: "",
    };
  },
  computed: {
    ...mapState(["ip"]),
  },
  methods: {
    // 处理数据
    calcContent() {
      this.content = this.content.replace(
        /class="ql-align-center"/g,
        "style='text-align:center'"
      );
      this.content = this.content.replace(
        /<img src/g,
        '<img style="width:100%" src'
      );
      this.content = this.content.replaceAll("\t", "&nbsp;&nbsp;&nbsp;&nbsp;");
      this.content = this.content.replace(
        /<a[^>]*?href=[\\]{0,1}['""]{0,1}[^'""]*?http*?['""]{0,1}[^>]*?>*[^>]*?<\/a>/g,
        ""
      );

      if (this.content) {
        this.content = `<div style="white-space:pre-wrap;">${this.content}</div>`;
      }
    },
    // 插入图片
    handleChangeEdit(row) {
      const { data } = row.file.response || { data: null };
      if (data) {
        this.content =
          `<div style="white-space:pre-wrap;">` +
          `${this.content ? this.content : ""}` +
          `<p style="text-align:center;"><img style="width: 100%" src="${data}" /></p><p></p>` +
          `</div>`;
      }
    },
    getList(params) {
      this.axios("/dq_admin/faq/list", {
        params: params,
      }).then((res) => {
        let list = res.data.records;
        for (let i = 0; i < list.length; i++) {
          if (list[i].type == 1) {
            list[i].type = "协议";
          }
          list[i].serialNumber = i + 1;
        }
        this.data = list;
        this.pagination.total = res.data.total;
      });
    },
    changePage(page) {
      const { current, pageSize } = page;

      this.pagination.current = current;
      this.params.pageNum = current;
      this.params.pageSize = pageSize;
      this.getList(this.params);
    },
    search(e) {
      if (this.searchInpt == "") {
        this.$message.warning("请输入标题再进行搜索");
      } else {
        this.params.page = 1;
        this.params.title = this.searchInpt;
        this.getList(this.params);
        this.pagination.current = 1;
      }
    },
    valueNull() {
      if (this.searchInpt == "") {
        this.params.title = this.searchInpt;
        this.getList(this.params);
        this.pagination.current = 1;
      }
    },
    openModal(row) {
      if (row) {
        this.visible = true;
        this.innerTitle = "编辑";
        this.id = row.id;
        this.axios("/dq_admin/faq/getById", {
          params: { id: row.id },
        }).then((res) => {
          this.content = res.data.content;
        });
        this.title = row.title;
        this.sort = row.sort;
        this.content = row.content;
        this.openType = 1;
      } else {
        this.openType = 2;
        this.sort = "";
        this.title = "";
        this.content = "";
        this.visible = true;
        this.innerTitle = "新增问题";
      }
    },
    remove(id) {
      this.axios("/dq_admin/faq/delById", {
        params: { id: id },
      }).then((res) => {
        this.getList(this.params);

        this.$message.success("删除成功");
      });
    },
    hideModal() {
      if (!this.title) return this.$message.warning("请输入文本标题");
      if (!this.content) return this.$message.warning("请输入文本内容");
      this.calcContent();
      if (this.openType == 1) {
        this.axios
          .post("/dq_admin/faq/edit", {
            imgUrl: "",
            content: this.content,
            title: this.title,
            sort: this.sort,
            ype: 1,
            id: this.id,
          })
          .then((res) => {
            this.getList(this.params);
            this.visible = false;
            this.$message.success("修改成功");
          });
      } else {
        this.axios
          .post("/dq_admin/faq/add", {
            imgUrl: "",
            content: this.content,
            title: this.title,
            sort: this.sort,
            type: 1,
          })
          .then((res) => {
            this.getList(this.params);
            this.visible = false;
            this.$message.success("新增成功");
          });
      }
    },
    resetForms() {},
  },
  mounted() {
    this.getList(this.params);
  },
};
</script>
<style lang="scss" scoped>
.page {
  display: flex;
  flex-direction: column;
}
.page-body {
  flex: 1;
  overflow: auto;
}
.top {
  input {
    width: 250px;
    margin-right: 25px;
    margin-bottom: 20px;
  }
  button {
    width: 65px;
    height: 28px;
    border: none;
    background: #1890ff;
    color: #ffffff;
    border-radius: 5px;
    margin-right: 25px;
    outline-style: none;
    cursor: pointer;
  }
}
.modal {
  input {
    margin-bottom: 5px;
  }
}
</style>